<template>
  <div class="amount-select">
    <SuggestedAmountsForAppeals
      v-if="appealPresent"
      @selected="presetSelected"
    />
    <SuggestedAmounts
      v-else
      @selected="presetSelected"
    />
    <div class="custom-amount-wrapper">
      <FormField
        class="custom-amount"
        :label="$t('donationForm.customAmount.label')"
        v-model="amount"
        type="number"
        :validation="v$.amount"
      />
    </div>
    <p
      v-if="$t('donationForm.additionalInfo')"
      id="additional-info"
    >
      <small v-html="$t('donationForm.additionalInfo')" />
    </p>
  </div>
</template>
<script>
import SuggestedAmounts from './SuggestedAmounts'
import SuggestedAmountsForAppeals from './SuggestedAmountsForAppeals'
import FormField from '@/components/form/FormField'
import { required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { useRootStore } from '@/stores/root'
import { useDonationStore } from '@/stores/donation'

export default {
  name: 'AmountPreselect',
  setup () {
    const rootStore = useRootStore()
    const donationStore = useDonationStore()
    return { rootStore, donationStore, v$: useVuelidate() }
  },
  components: { SuggestedAmounts, SuggestedAmountsForAppeals, FormField },
  computed: {
    currency () { return this.donationStore.currency },
    amount: {
      get () { return this.donationStore.amount },
      set (newValue) { this.donationStore.setAmount(`${newValue}`) }
    },
    appealPresent () {
      return (this.rootStore.appeal) && this.$tm(`appeals.${this.rootStore.appeal}`)[0]
    }
  },
  methods: {
    presetSelected (val) { this.donationStore.setAmount(val) }
  },
  validations () {
    return {
      amount: {
        required,
        amount: val => (val !== null) && (val >= this.$l10n.minAmountFor(this.currency))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .jump-to-more-ways-to-give{
    text-align: right;
    margin: 1.25em 0 0;
    a{
      color: white;
      transition: color 150ms ease-in-out;
      &:hover, &:active{
        color: $color-web-orange;
      }
      &:after{
        border-style: solid;
        border-width: 0.25rem 0.25rem 0 0;
        content: '';
        display: inline-block;
        height: 0.45rem;
        left: 0.15rem;
        position: relative;
        top: 0.35rem;
        vertical-align: top;
        width: 0.45rem;
        transform: rotate(135deg);
      }
    }
  }

  #additional-info {
    .styled-link {
      color: white;
    }
  }

  label, input {
    display: inline-block;
  }

  label {
    margin-right: 0.5em;
  }

  input {
    padding: 0.3em;
    width: 100px;
    height: 35px;
  }

  button {
    display: inline-block;
    margin-top: 1.5em;
    padding: 0.4em 1em;
    outline: none;
    box-shadow: 2px 2px 6px rgba(black, 0.5);
    border: none;
    cursor: pointer;
    background-color: $color-web-orange;
    text-transform: uppercase;
    font-size: 0.9em;
    font-weight: bold;
    height: 35px;

  &:active {
     transform: translate(1px, 1px);
     box-shadow: 1px 1px 4px rgba(black, 0.5);
     outline: none;
   }

  }
</style>
